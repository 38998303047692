/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus,react/jsx-one-expression-per-line,react/no-unescaped-entities */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import { cover } from "intrinsic-scale";
import { connect } from "react-redux";
import { navigate } from "gatsby";
import useResizeObserver from "use-resize-observer/polyfilled";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import HiddenJs from "@material-ui/core/Hidden/HiddenJs";
import checkout from "../images/checkout.jpg";
import close from "../images/close.svg";
import AnimatedButton from "./animated-button";
import * as Data from "../models/data";
import * as Cart from "../models/cart";
import { Checkout } from "./checkout";
import Toast from "./toast";
import * as eventLogger from "./eventLogger";

const useStyles = makeStyles(theme => ({
  loading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  container: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    height: "100vh",
    [theme.breakpoints.down(1100)]: {
      flexWrap: "wrap",
      overflow: "scroll",
      height: "100%",
    },
  },
  closeButton: {
    zIndex: 999,
    position: "absolute",
    left: "auto",
    top: "30px",
    right: "30px",
    bottom: "auto",
    padding: "10px",
    cursor: "pointer",
    "& > img": {
      width: "20px",
    },
    [theme.breakpoints.down(991)]: {
      top: "0px",
      right: "0px",
      zIndex: "999",
      padding: "20px",
      backgroundColor: "#fff",
    },
  },
  roomImage: {
    position: "relative",
    overflow: "hidden",
    flexGrow: 1,
    [theme.breakpoints.down(1100)]: {
      height: "62vw",
    },
    [theme.breakpoints.down(767)]: {
      width: "100vw",
      height: "80vw",
    },
  },
  imageWrapper: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    objectPosition: "top",
  },
  resultWrapper: {
    position: "absolute",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  resultContainer: {
    borderRadius: "2px",
    boxShadow: "3px 3px 10px 0 rgba(0, 0, 0, 0.35)",
  },
  resultImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    objectPosition: "50% 50%",
  },
  details: {
    width: "38vw",
    overflow: "scroll",
    minWidth: "38vw",
    padding: "65px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    [theme.breakpoints.down(1100)]: {
      justifyContent: "center",
      paddingBottom: 0,
    },
    [theme.breakpoints.down(767)]: {
      width: "100vw",
      height: "auto",
      padding: "60px 8vw 0px",
      backgroundColor: "#fff",
    },
  },
  yourPrints: {
    marginTop: "0px",
    marginBottom: "20px",
    fontFamily: "'Work Sans', sans-serif",
    fontSize: "48px",
    lineHeight: "125%",
    fontWeight: "600",
    [theme.breakpoints.down(1100)]: {
      fontSize: "36px",
    },
    [theme.breakpoints.down(767)]: {
      marginBottom: 0,
    },
  },
  description: {
    marginBottom: "30px",
    opacity: "0.6",
    color: "#222",
    lineHeight: "175%",
    fontSize: "18px",
    fontWeight: "500",
    fontFamily: "Roboto, sans-serif",
    [theme.breakpoints.down(1100)]: {
      marginBottom: 0,
    },
    [theme.breakpoints.down(767)]: {
      marginBottom: 0,
      marginTop: "20px",
    },
  },
  largeScreenButton: {
    marginBottom: 20,
    minHeight: 70,
    [theme.breakpoints.down(1100)]: {
      display: "none",
    },
  },
  checkoutButtonLargeScreen: {
    width: "100%",
  },
  bottom: {
    width: "100%",
    padding: "80px 8vw",
    [theme.breakpoints.down(1100)]: {
      padding: "45px 8vw",
    },
    [theme.breakpoints.down(767)]: {
      paddingTop: "35px",
    },
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "center",
    marginTop: 35,
  },
  mobileScreenButton: {
    width: "50vw",
    [theme.breakpoints.down(767)]: {
      width: "100%",
    },
  },
}));

const ROOM_WIDTH_IN_INCH = 65;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide {...props} direction="left" ref={ref} timeout={700} />;
});

const Order = ({
  isLoading,
  canvasSizes,
  isVisible,
  toggle,
  uploadedImage,
  artStyle,
  stylizedUrl,
  addToCart,
  uploadedStyle,
}) => {
  const classes = useStyles({ stylizedUrl });
  const [selected, setSelected] = useState(0);
  const [errMsg, setErrMsg] = useState(null);
  const [pixelsForOneIch, setPixelsForOneInch] = useState(0);
  const [yOffset, setYOffset] = useState(0);

  const { ref } = useResizeObserver({
    onResize: ({ width, height }) => {
      if (ref.current) {
        const actualImgSize = cover(width, height, 1600, 1600);
        const pixelsForOneInch = actualImgSize.width / ROOM_WIDTH_IN_INCH;
        setPixelsForOneInch(pixelsForOneInch);
        setYOffset(actualImgSize.y * -1);
      }
    },
  });

  const handleBuyClick = () => {
    const item = {
      canvas: canvasSizes[selected],
      contentImage: uploadedImage,
      presetStyle: artStyle,
      customStyle: uploadedStyle,
      id: Date.now(),
    };

    addToCart(item);
    eventLogger.logBuyClick(item);
    navigate("/checkout");
  };

  const handleClose = () => {
    toggle();
  };

  const handleChange = event => {
    if (event.target.value !== selected) {
      setSelected(Number(event.target.value));
      eventLogger.logCanvasSizeClick(canvasSizes[selected]);
    }
  };

  const isVertical = uploadedImage
    ? uploadedImage.originalHeight > uploadedImage.originalWidth
    : false;

  const widthField = !isVertical ? "canvasWidth" : "canvasHeight";
  const heightField = !isVertical ? "canvasHeight" : "canvasWidth";

  return (
    <Dialog
      fullScreen
      open={isVisible}
      onClose={toggle}
      TransitionComponent={Transition}
      keepMounted
      scroll="body"
    >
      <Toast
        setOpen={() => setErrMsg(null)}
        message={errMsg || ""}
        open={errMsg !== null}
        severity="error"
      />
      <div className={classes.closeButton} onClick={handleClose} role="button">
        <img src={close} alt="" />
      </div>
      {isLoading && (
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      )}
      {!isLoading && canvasSizes && (
        <div className={classes.container}>
          <div className={classes.roomImage}>
            <div
              className={classes.resultWrapper}
              style={{ height: `calc(56% + ${yOffset}px)` }}
            >
              <div
                className={classes.resultContainer}
                style={{
                  width: canvasSizes[selected][widthField] * pixelsForOneIch,
                  height: canvasSizes[selected][heightField] * pixelsForOneIch,
                }}
              >
                <img src={stylizedUrl} alt="" className={classes.resultImage} />
              </div>
            </div>
            <img
              src={checkout}
              alt=""
              className={classes.imageWrapper}
              id="room"
              ref={ref}
            />
          </div>
          <div className={classes.details}>
            <Typography className={classes.yourPrints} variant="h2">
              Order
              <br />
              Your Canvas
            </Typography>
            <p className={classes.description}>
              All orders come with a 30-day money back guarantee. If you are not
              happy with your canvas, email contact@imagineart.ai, and we'll be
              more than happy to refund your order. Just let us know why (:
            </p>
            <HiddenJs lgDown>
              <Checkout
                reverted={isVertical}
                selected={selected}
                handleChange={handleChange}
                canvasSizes={canvasSizes}
              />
            </HiddenJs>
            <div className={classes.largeScreenButton}>
              <AnimatedButton
                label="Checkout"
                id="checkout"
                className={classes.checkoutButtonLargeScreen}
                onClick={handleBuyClick}
              />
            </div>
          </div>
          <HiddenJs xlUp>
            <div className={classes.bottom}>
              <Checkout
                reverted={isVertical}
                selected={selected}
                handleChange={handleChange}
                canvasSizes={canvasSizes}
              />
              <div className={classes.buttonWrapper}>
                <AnimatedButton
                  label="Checkout"
                  id="checkout-2"
                  className={classes.mobileScreenButton}
                  onClick={handleBuyClick}
                />
              </div>
            </div>
          </HiddenJs>
        </div>
      )}
    </Dialog>
  );
};

Order.propTypes = {
  isLoading: PropTypes.bool,
  canvasSizes: PropTypes.array,
  isVisible: PropTypes.bool,
  toggle: PropTypes.func.isRequired,
  addToCart: PropTypes.func.isRequired,
  uploadedImage: PropTypes.object,
  artStyle: PropTypes.number,
  uploadedStyle: PropTypes.object,
  stylizedUrl: PropTypes.string,
};

const mapStateToProps = state => ({
  isLoading: Data.isLoadingCanvasSizes(state),
  canvasSizes: Data.getCanvasSizes(state),
  isVisible: Data.isOrderVisible(state),
  uploadedImage: Data.getUploadedImage(state),
  artStyle: Data.getArtStyle(state),
  uploadedStyle: Data.getUploadedStyle(state),
  stylizedUrl: Data.getStylizedUrl(state),
});

const mapDispatchToProps = dispatch => ({
  toggle: Data.toggleOrderPage(dispatch),
  addToCart: Cart.addToCart(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Order);
