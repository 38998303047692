import config from "../../config";

export const extractImageInfo = fileInfo => {
  const { width, height } = fileInfo.originalImageInfo;
  const scalingFactor = Math.sqrt((width * height) / config.resolution);
  const resizedWidth = Math.floor(width / scalingFactor);
  const resizedHeight = Math.floor(height / scalingFactor);

  return {
    url: `${fileInfo.cdnUrl}-/autorotate/yes/`,
    width: resizedWidth,
    height: resizedHeight,
    originalWidth: width,
    originalHeight: height,
  };
};
