import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  fullPageContainer: {
    width: "100%",
    height: "100vh",
    padding: "80px 8vw",
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(3, 2),
    },
    [theme.breakpoints.down(767)]: {
      height: "auto",
    },
  },
}));

const FullPageContainer = ({ children, className }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.fullPageContainer, className)}>{children}</div>
  );
};

FullPageContainer.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default FullPageContainer;
