/* eslint-disable react/jsx-one-expression-per-line */
import React from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import _ from "lodash";
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Img from "gatsby-image/withIEPolyfill";
import arrowDown from "../images/arrow-down.svg";
import OverlineText from "./typography/overline";

const titles = [
  [
    "Step 1",
    "+",
    "Upload your photo",
    "step1.childImageSharp.fluid",
    "step4.childImageSharp.fluid",
  ],
  [
    "Step 2",
    "=",
    "Choose style",
    "step2.childImageSharp.fluid",
    "step5.childImageSharp.fluid",
  ],
  [
    "Step 3",
    "",
    "Get your artwork!",
    "step3.childImageSharp.fluid",
    "step6.childImageSharp.fluid",
  ],
];

const useStyles = makeStyles(theme => ({
  container: {
    position: "relative",
    padding: "80px 8vw",
    display: "flex",
    "& > div:not(:last-child)": {
      marginRight: 16,
    },
    [theme.breakpoints.down(767)]: {
      paddingRight: "0vw",
      flexDirection: "column",
      "& > div:not(:last-child)": {
        marginRight: 0,
      },
    },
  },
  column: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  titleWrapper: {
    display: "flex",
    marginBottom: "10px",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  step: {
    marginTop: "0px",
    marginBottom: "0px",
    fontFamily: "'Work Sans', sans-serif",
    fontSize: "24px",
    fontWeight: "600",
    display: "inline",
    [theme.breakpoints.down(767)]: {
      fontSize: "36px",
    },
  },
  character: {
    marginTop: "0px",
    marginBottom: "0px",
    position: "absolute",
    right: -14,
    fontWeight: "600",
    display: "inline",
    fontFamily: "'Work Sans', sans-serif",
    fontSize: "24px",
    lineHeight: "24px",
    [theme.breakpoints.down(767)]: {
      display: "none",
    },
  },
  desc: {
    marginTop: "0px",
    fontFamily: "'Work Sans', sans-serif",
    fontSize: "20px",
    lineHeight: "200%",
    fontWeight: 400,
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center",
    marginBottom: "20px",
    [theme.breakpoints.down(767)]: {
      marginBottom: "0px",
    },
  },
  imgWrapper: {
    marginTop: "auto",
  },
  firstImg: {
    margin: "16px 0",
  },
  secondImg: {
    [theme.breakpoints.down(767)]: {
      display: "none",
    },
  },
  sideDesc: {
    flex: 1,
    marginTop: "auto",
    paddingTop: "80px",
    paddingBottom: "80px",
    paddingLeft: "80px",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    backgroundColor: "#000a2a",
    [theme.breakpoints.down(991)]: {
      paddingTop: "60px",
      paddingBottom: "60px",
      paddingLeft: "60px",
    },
    [theme.breakpoints.down(767)]: {
      paddingRight: "8vw",
      paddingLeft: "8vw",
      flexDirection: "row",
    },
    [theme.breakpoints.down(479)]: {
      paddingRight: "40px",
      paddingLeft: "40px",
      flexDirection: "column",
    },
  },
  spacer: {
    height: 114,
    [theme.breakpoints.down(767)]: {
      display: "none",
    },
  },
  sideDescBg: {
    position: "absolute",
    top: "0px",
    right: "-8vw",
    bottom: "0px",
    width: "8vw",
    backgroundColor: "#000a2a",
    [theme.breakpoints.down(767)]: {
      display: "none",
    },
  },
  asEasyAs: {
    color: "#fff",
    marginBottom: "30px",
    opacity: "0.6",
    fontSize: "20px",
    letterSpacing: "1px",
    textTransform: "uppercase",
    lineHeight: "200%",
    fontWeight: "300",
    [theme.breakpoints.down(991)]: {
      fontSize: "14px",
    },
  },
  tryYourself: {
    textDecoration: "none",
    maxWidth: "100%",
    display: "inline-block",
    [theme.breakpoints.down(767)]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      alignItems: "flex-end",
      alignSelf: "stretch",
    },
    [theme.breakpoints.down(479)]: {
      alignItems: "flex-start",
    },
  },
  tryText: {
    fontWeight: "400",
    color: "#fff",
    marginTop: "0px",
    marginBottom: "0px",
    fontFamily: "'Work Sans', sans-serif",
    fontSize: "3.333vw",
    [theme.breakpoints.down(767)]: {
      fontSize: "32px",
    },
  },
  arrow: {
    marginTop: "40px",
    maxWidth: "100%",
    display: "inline-block",
    [theme.breakpoints.down(991)]: {
      width: "16px",
    },
  },
}));

const Steps = () => {
  const classes = useStyles();

  const data = useStaticQuery(graphql`
    query {
      step1: file(relativePath: { eq: "steps/step-1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      step2: file(relativePath: { eq: "steps/step-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      step3: file(relativePath: { eq: "steps/step-3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      step4: file(relativePath: { eq: "steps/step-4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      step5: file(relativePath: { eq: "steps/step-5.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      step6: file(relativePath: { eq: "steps/step-6.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <div className={classes.container}>
      {titles.map(([title, character, desc, img1, img2], i) => (
        <div className={classes.column} key={title}>
          <div className={classes.titleWrapper}>
            <Typography variant="h3" className={classes.step}>
              Step {i + 1}
            </Typography>
            <Typography variant="h3" className={classes.character}>
              {character}
            </Typography>
          </div>
          <Typography variant="h5" className={classes.desc}>
            {desc}
          </Typography>
          <div className={classes.imgWrapper}>
            <Img
              className={classes.firstImg}
              objectFit="cover"
              objectPosition="50% 50%"
              fluid={_.get(data, img1)}
            />
            <Img
              className={classes.secondImg}
              objectFit="cover"
              objectPosition="50% 50%"
              fluid={_.get(data, img2)}
            />
          </div>
        </div>
      ))}
      <div className={classes.column}>
        <div className={classes.spacer} />
        <div className={classes.sideDesc}>
          <OverlineText className={classes.asEasyAs}>
            Create your own <br />
            artwork in 3 steps
          </OverlineText>
          <Link to="/#try" className={classes.tryYourself}>
            <Typography className={classes.tryText} variant="h3">
              Try It yourself
            </Typography>
            <img src={arrowDown} alt="" className={classes.arrow} />
          </Link>
          <div className={classes.sideDescBg} />
        </div>
      </div>
    </div>
  );
};

export default Steps;
