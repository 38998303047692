import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
  text: {
    color: "#222",
    fontSize: "12px",
    lineHeight: "12px",
    letterSpacing: "1px",
    textTransform: "uppercase",
    opacity: 0.6,
    fontFamily: "Roboto, sans-serif",
  },
}));

const OverlineText = ({ children, className }) => {
  const classes = useStyles();

  return (
    <Typography variant="overline" className={clsx(classes.text, className)}>
      {children}
    </Typography>
  );
};

OverlineText.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default OverlineText;
