import React, { useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import clsx from "clsx";
import ImageUpload from "./image-upload";
import ArtStyles from "./art-styles";
import api from "../api";
import OverlineText from "./typography/overline";
import * as Data from "../models/data";
import AnimatedButton from "./animated-button";
import arrowRight from "../images/arrow-right-dark.svg";
import { smoothScroll } from "./layout";
import * as eventLogger from "./eventLogger";

const {
  StylizeMessage: { Style },
} = require("../api/style-transfer_grpc_web_pb");

const useStyles = makeStyles(theme => ({
  container: {
    position: "relative",
    padding: "80px 8vw",
    paddingBottom: "160px",
  },
  title: {
    marginTop: "30px",
    marginBottom: "60px",
    fontSize: "48px",
    lineHeight: "125%",
    fontWeight: 600,
    [theme.breakpoints.down(991)]: {
      fontSize: "36px",
    },
  },
  wrapper: {
    display: "flex",
    marginBottom: "80px",
    [theme.breakpoints.down(767)]: {
      flexDirection: "column",
    },
  },
  uploader: {
    width: "100%",
    minWidth: 300,
    marginRight: "60px",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down(991)]: {
      marginRight: "20px",
    },
    [theme.breakpoints.down(767)]: {
      marginRight: 0,
      marginBottom: "20px",
    },
  },
  artStylesContainer: {
    width: 342,
    minWidth: 342,
    [theme.breakpoints.down(991)]: {
      width: 232,
      minWidth: 232,
    },
    [theme.breakpoints.down(767)]: {
      minWidth: 300,
      width: "100%",
    },
    [theme.breakpoints.down(479)]: {
      width: "100vw",
      height: "105vw",
      marginRight: "-8vw",
      marginLeft: "-8vw",
      padding: "0px",
      borderStyle: "none",
      overflowY: "scroll",
    },
  },
  stepTitle: {
    marginBottom: "30px",
    fontSize: "24px",
    lineHeight: "150%",
    fontWeight: 700,
  },
  buttons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    [theme.breakpoints.down(479)]: {
      flexDirection: "column",
    },
  },
  orderButton: {
    borderRadius: 0,
    marginLeft: 20,
    height: 52,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
    [theme.breakpoints.down(479)]: {
      marginTop: "20px",
      width: "100%",
      marginLeft: 0,
    },
  },
  orderButtonText: {
    textTransform: "none",
    fontSize: "16px",
    lineHeight: "100%",
    fontFamily: "'Work Sans', sans-serif",
    color: "#fff",
    transition: "all 300ms ease",
    padding: "15px 32px 17px",
    "&:hover": {
      color: "rgb(34,34,34)",
    },
  },
  orderButtonProgress: {
    position: "absolute",
    top: 8,
    width: 35,
    height: 35,
    color: theme.palette.secondary.main,
    marginLeft: "auto",
    marginRight: "auto",
  },
  orderButtonDisabled: {
    cursor: "default",
    opacity: 0.65,
  },
  arrow: {
    width: "80px",
    userSelect: "none",
    padding: "10px",
  },
  arrowContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginBottom: 10,
    marginTop: -15,
    [theme.breakpoints.up(479)]: {
      display: "none",
    },
  },
  imgArrow: {
    fontSize: "40px",
    border: "0",
    maxWidth: "100%",
    verticalAlign: "middle",
    display: "inline-block",
    width: "100%",
    height: 20,
  },
}));

const Uploader = ({
  uploadedImage,
  artStyle,
  isLoadingCanvas,
  uploadedStyle,
  fetchCanvasSizes,
  lastUsedStyle,
  setLastUsedStyle,
  setStylizedUrl,
  stylizedUrl,
}) => {
  const classes = useStyles();
  const [progress, setProgress] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleStylizeClick = () => {
    setProgress(0);
    setLoading(true);
    setStylizedUrl(null);
    setLastUsedStyle();
    eventLogger.logImaginizeClick(artStyle);

    if (window.innerWidth < 767) {
      smoothScroll.animateScroll(document.querySelector("#try"), null, {
        speed: 1500,
        easing: "easeOutCubic",
        offset: -160,
      });
    }

    const stream = api.stylizeImage(
      uploadedImage.url,
      artStyle,
      _.get(uploadedStyle, "url", null)
    );

    let lastUrl;

    stream.on("data", data => {
      setProgress(
        Math.floor((data.getFrameNum() / data.getFrameTotal()) * 100)
      );
      const blob = new Blob([data.getBytes()], { type: "image/jpeg" });
      const urlCreator = window.URL || window.webkitURL;
      lastUrl = urlCreator.createObjectURL(blob);
      const img = document.querySelector("#photo");
      if (img) {
        URL.revokeObjectURL(img.src);
        img.src = lastUrl;
      } else {
        stream.cancel();
        setLoading(false);
      }
    });

    stream.on("end", () => {
      setProgress(null);
      setStylizedUrl(lastUrl);
      setLoading(false);
    });
  };

  const handleOrderPrintsClick = async () => {
    fetchCanvasSizes();
    eventLogger.logOrderPrintsClick(uploadedImage, artStyle);
  };

  const isSmallScreen = () => window.innerWidth < 767;

  return (
    <div className={classes.container} id="try">
      <OverlineText>Let&apos;s get started</OverlineText>
      <Typography className={classes.title} variant="h2">
        Try it now!
      </Typography>
      <div className={classes.wrapper}>
        <div className={classes.uploader}>
          <Typography className={classes.stepTitle} variant="h6">
            Step 1: Upload photo
            {loading && isSmallScreen() && (
              <CircularProgress size={24} style={{ marginLeft: 18 }} />
            )}
          </Typography>
          <ImageUpload />
        </div>
        <div>
          <Typography className={classes.stepTitle} variant="h6">
            Step 2: Choose style
          </Typography>
          <div className={classes.arrowContainer}>
            <OverlineText>Scroll right</OverlineText>
            <div className={classes.arrow}>
              <img src={arrowRight} alt="" className={classes.imgArrow} />
            </div>
          </div>
          <div className={classes.artStylesContainer}>
            <ArtStyles />
          </div>
        </div>
      </div>
      <div className={classes.buttons}>
        <AnimatedButton
          label="Imaginize!"
          id="imaginize"
          loading={loading}
          loadingProgress={progress}
          onClick={handleStylizeClick}
          disabled={
            !uploadedImage ||
            (!uploadedStyle && artStyle === Style.NONE) ||
            loading ||
            (uploadedStyle && uploadedStyle.url === lastUsedStyle) ||
            artStyle === lastUsedStyle
          }
        />
        {stylizedUrl && (
          <Button
            variant="contained"
            color="secondary"
            disableElevation
            disableRipple
            disabled={isLoadingCanvas}
            className={clsx(classes.orderButton, {
              [classes.orderButtonDisabled]: isLoadingCanvas,
            })}
            classes={{ root: classes.orderButtonText }}
            onClick={handleOrderPrintsClick}
          >
            Order Prints
            {isLoadingCanvas && (
              <CircularProgress className={classes.orderButtonProgress} />
            )}
          </Button>
        )}
      </div>
    </div>
  );
};

Uploader.propTypes = {
  uploadedImage: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
    url: PropTypes.string,
  }),
  uploadedStyle: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
    url: PropTypes.string,
  }),
  artStyle: PropTypes.number,
  isLoadingCanvas: PropTypes.bool,
  fetchCanvasSizes: PropTypes.func.isRequired,
  setLastUsedStyle: PropTypes.func.isRequired,
  setStylizedUrl: PropTypes.func.isRequired,
  stylizedUrl: PropTypes.string,
  lastUsedStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const mapStateToProps = state => ({
  isLoadingCanvas: Data.isLoadingCanvasSizes(state),
  uploadedImage: Data.getUploadedImage(state),
  uploadedStyle: Data.getUploadedStyle(state),
  artStyle: Data.getArtStyle(state),
  lastUsedStyle: Data.getLastUsedStyle(state),
  stylizedUrl: Data.getStylizedUrl(state),
});

const mapDispatchToProps = dispatch => ({
  fetchCanvasSizes: Data.fetchCanvasSizes(dispatch),
  setLastUsedStyle: Data.setLastUsedStyle(dispatch),
  setStylizedUrl: Data.setStylizedUrl(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Uploader);
