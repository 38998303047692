import React from "react";
import SEO from "../components/seo";
import Layout from "../components/layout";
import Hero from "../components/hero";
import TheMagic from "../components/the-magic";
import Steps from "../components/steps";
import Uploader from "../components/uploader";
import OnYourWall from "../components/on-your-wall";
import Order from "../components/order";

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Home" />
      <Hero />
      <TheMagic />
      <Steps />
      <Uploader />
      <OnYourWall />
      <Order />
    </Layout>
  );
};

export default IndexPage;
