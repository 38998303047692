import React, { useState } from "react";
import { Link } from "gatsby";
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import sliderIndicator from "../images/slider-indicator.svg";
import FullPageContainer from "./full-page";
import OverlineText from "./typography/overline";
import Slider from "./slider";

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: theme.palette.primary.main,
    padding: "80px 8vw",
  },
  content: {
    position: "absolute",
    top: "auto",
    right: "auto",
    bottom: "122px",
    zIndex: 9,
    [theme.breakpoints.down(767)]: {
      position: "relative",
      top: "80px",
      bottom: "0px",
    },
  },
  mainTitleWrapper: {
    marginBottom: 60,
    [theme.breakpoints.down(767)]: {
      marginBottom: 40,
    },
  },
  mainTitle: {
    fontSize: "5.55vw",
    lineHeight: "110%",
    fontWeight: "600",
    color: "#fff",
    [theme.breakpoints.down(991)]: {
      fontSize: "56px",
      lineHeight: "125%",
    },
    [theme.breakpoints.down(479)]: {
      fontSize: "48px",
    },
  },
  subtitle: {
    color: "#fff",
    fontSize: "20px",
    lineHeight: "200%",
    fontWeight: "500",
    width: "35vw",
    [theme.breakpoints.down(767)]: {
      fontSize: "18px",
      width: "100%",
    },
  },
  tryItWrapper: {
    position: "absolute",
    left: "auto",
    top: "auto",
    right: "0%",
    bottom: "0%",
    zIndex: 9,
    cursor: "pointer",
    padding: "60px 8vw 60px 60px",
    backgroundColor: theme.palette.background.default,
    color: "#222",
    [theme.breakpoints.down(991)]: {
      paddingTop: "40px",
      paddingBottom: "40px",
      paddingLeft: "40px",
    },
    [theme.breakpoints.down(767)]: {
      display: "none",
    },
  },
  likeTheMagic: {
    lineHeight: "100%",
  },
  tryText: {
    fontWeight: 500,
    marginTop: "22px",
    fontSize: "24px",
    lineHeight: "150%",
    fontFamily: "'Work Sans', sans-serif",
    marginBottom: 0,
    textDecoration: "underline",
  },
  indicatorWrapper: {
    position: "absolute",
    top: "auto",
    right: "500px",
    bottom: "62px",
    display: "flex",
    height: "12px",
    marginRight: "8vw",
    alignItems: "center",
    [theme.breakpoints.down(991)]: {
      right: "460px",
    },
    [theme.breakpoints.down(767)]: {
      display: "none",
    },
  },
  indicatorNumber: {
    paddingRight: "10px",
    paddingLeft: "10px",
    fontFamily: "'Work Sans', sans-serif",
    color: "#fff",
    fontSize: "12px",
    lineHeight: "12px",
    letterSpacing: "1px",
  },
  indicators: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  indicator: {
    display: "inline-block",
    width: "36px",
    marginRight: "4px",
    marginLeft: "4px",
    opacity: 0.2,
    transition: "opacity 600ms ease",
  },
  activeIndicator: {
    opacity: 1,
  },
}));

const Hero = () => {
  const classes = useStyles();
  const [slideNumber, setSlideNumber] = useState(0);

  return (
    <FullPageContainer className={classes.container}>
      <div className={classes.content}>
        <div className={classes.mainTitleWrapper}>
          <Typography variant="h1" className={classes.mainTitle}>
            Imagine it.
          </Typography>
          <Typography variant="h1" className={classes.mainTitle}>
            Create it.
          </Typography>
        </div>
        <Typography variant="h5" className={classes.subtitle}>
          Artwork that tells your story.
        </Typography>
      </div>
      <Slider setSlide={setSlideNumber} />
      <Link to="/#try" className={classes.tryItWrapper}>
        <OverlineText className={classes.likeTheMagic}>
          Your Picture, Your Style
        </OverlineText>
        <Typography className={classes.tryText} variant="h4">
          Create your own!
        </Typography>
      </Link>
      <div className={classes.indicatorWrapper}>
        <div className={classes.indicatorNumber}>01</div>
        <div className={classes.indicators}>
          <img
            src={sliderIndicator}
            alt=""
            className={clsx(classes.indicator, {
              [classes.activeIndicator]: slideNumber === 0,
            })}
          />
          <img
            src={sliderIndicator}
            alt=""
            className={clsx(classes.indicator, {
              [classes.activeIndicator]: slideNumber === 1,
            })}
          />
          <img
            src={sliderIndicator}
            alt=""
            className={clsx(classes.indicator, {
              [classes.activeIndicator]: slideNumber === 2,
            })}
          />
        </div>
        <div className={classes.indicatorNumber}>03</div>
      </div>
    </FullPageContainer>
  );
};

export default Hero;
