import React, { useEffect, useState } from "react";
import clsx from "clsx";
import uploadcareTabEffects from "uploadcare-widget-tab-effects";
import { connect, useStore } from "react-redux";
import PropTypes from "prop-types";
import uploadcare from "uploadcare-widget";
import { makeStyles } from "@material-ui/core";
import Img from "react-image";
import LinearProgress from "@material-ui/core/LinearProgress";
import CircularProgress from "@material-ui/core/CircularProgress";
import config from "../../config";
import "../theme/uploadcare.css";
import { extractImageInfo } from "../utils";
import * as Data from "../models/data";

const useStyles = makeStyles(theme => ({
  uploaderWrapper: {
    marginTop: "auto",
    height: 480,
    width: "100%",
    [theme.breakpoints.down(767)]: {
      height: "auto",
    },
    transition: "all 200ms ease",
  },
  uploaderPlaceholder: {
    height: "100%",
    minHeight: 120,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  image: {
    maxHeight: "100%",
    maxWidth: "100%",
    zIndex: 2,
  },
  hoverOverlay: {
    position: "absolute",
    width: "100%",
    height: "100%",
    display: "flex",
    zIndex: 3,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(34, 34, 34, 0.9)",
    opacity: 0,
    transition: "all 200ms ease",
    transitionDelay: "200ms",
  },
  hoverVisibleOnHover: {
    "&:hover": {
      opacity: 1,
    },
  },
  anotherImageButton: {
    width: "160px",
    marginBottom: "10px",
    borderColor: "#fff",
    backgroundColor: "transparent",
    height: "auto",
    padding: "9px 20px",
    fontSize: "12px",
    minWidth: "130px",
    justifyContent: "center",
    alignItems: "center",
    borderStyle: "solid",
    borderWidth: "1px",
    transition: "color 200ms ease, background-color 200ms ease",
    color: "#fff",
    lineHeight: "26px",
    textAlign: "center",
    textDecoration: "none",
    cursor: "pointer",
    borderRadius: "0",
    boxSizing: "border-box",
    fontFamily: "Roboto, sans-serif",
    fontWeight: 600,
    "&:hover": {
      backgroundColor: "#222",
      borderColor: "#222",
    },
  },
  downloadButton: {
    position: "absolute",
    backgroundColor: theme.palette.secondary.main,
    right: 0,
    bottom: 0,
    width: 100,
    textAlign: "center",
    padding: "8px 0px",
    zIndex: 4,
    fontSize: 14,
    fontFamily: "Roboto, sans-serif",
    cursor: "pointer",
    color: "black",
    textDecoration: "none",
    "&:visited": {
      color: "black",
    },
  },
}));
uploadcare.registerTab("preview", uploadcareTabEffects);

const ImageUpload = ({ image, setImage, setError, stylizedImageUrl }) => {
  const classes = useStyles();
  const [hoveredCounter, setCounter] = useState(0);
  const [uploadProgress, setUploadProgress] = useState(0);
  const store = useStore();

  const setupUploadCare = () => {
    const panel = uploadcare.openPanel("#uploader-placeholder", null, null, {
      publicKey: config.uploadCarePublicKey,
      imagesOnly: true,
      tabs: "instagram file url gdrive",
      previewStep: true,
      effects: "crop",
    });

    panel.done(instance => {
      instance.progress(status => {
        setUploadProgress(status.progress * 100);
      });

      instance.promise().done(info => {
        const newImage = extractImageInfo(info);

        const uploadedStyle = Data.getUploadedStyle(store.getState());
        const imageSize = newImage.originalWidth * newImage.originalHeight;

        if (uploadedStyle && imageSize < 750 * 750) {
          setError(
            `Image is too small. Image must be larger than 750x750 pixels when using custom style.`
          );
          setupUploadCare();
        } else if (imageSize < 500 * 500) {
          setError(
            `Image is too small. Image must be larger than 500x500 pixels.`
          );
          setupUploadCare();
        } else {
          setImage(newImage);
          setCounter(0);
          setUploadProgress(0);
        }
      });
    });
  };

  useEffect(() => {
    if (!image) {
      setupUploadCare();
    }
  }, [image]);

  const resetImage = () => {
    setImage(null);
  };

  const handleBlur = () => {
    setCounter(prev => prev + 1);
  };

  const style = image
    ? {
        maxWidth: image.width,
        maxHeight: image.height > 480 ? 480 : image.height,
        width: "100%",
        overflow: "hidden",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }
    : null;

  return (
    <div className={classes.uploaderWrapper}>
      <div id="uploader-placeholder" className={classes.uploaderPlaceholder}>
        {!image && (
          <div style={{ minWidth: 200 }}>
            <LinearProgress variant="determinate" value={uploadProgress} />
          </div>
        )}
        {image && (
          <div style={style}>
            <Img
              className={classes.image}
              id="photo"
              src={image ? image.url : null}
              style={{ objectFit: "contain", width: "100%" }}
              loader={<CircularProgress />}
            />
          </div>
        )}
        <div
          className={clsx(classes.hoverOverlay, {
            [classes.hoverVisibleOnHover]: !!image && hoveredCounter > 0,
          })}
          style={style}
          onMouseEnter={handleBlur}
        >
          <button
            type="button"
            className={classes.anotherImageButton}
            onClick={resetImage}
          >
            Try another image
          </button>
        </div>
        {stylizedImageUrl && (
          <a
            href={stylizedImageUrl}
            className={classes.downloadButton}
            download="imagine-art.png"
          >
            Download
          </a>
        )}
      </div>
    </div>
  );
};

ImageUpload.propTypes = {
  image: PropTypes.object,
  setImage: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  stylizedImageUrl: PropTypes.string,
};

const mapStateToProps = state => ({
  image: Data.getUploadedImage(state),
  stylizedImageUrl: Data.getStylizedUrl(state),
});

const mapDispatchToProps = dispatch => ({
  setImage: Data.setImage(dispatch),
  setError: Data.setError(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImageUpload);
