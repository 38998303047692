import React from "react";
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import OverlineText from "./typography/overline";
import AnimatedButton from "./animated-button";

const useStyles = makeStyles(theme => ({
  container: {
    position: "relative",
    padding: "80px 8vw",
    [theme.breakpoints.down(767)]: {
      paddingTop: "120px",
    },
    [theme.breakpoints.down(479)]: {
      paddingTop: "160px",
    },
  },
  aboutTech: {
    marginBottom: "30px",
    display: "block",
  },
  wrapper: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down(767)]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  howTheMagic: {
    marginTop: "0px",
    marginBottom: "0px",
    fontFamily: "'Work Sans', sans-serif",
    fontSize: "48px",
    lineHeight: "125%",
    fontWeight: "600",
    [theme.breakpoints.down(991)]: {
      fontSize: "36px",
    },
    [theme.breakpoints.down(767)]: {
      marginBottom: "40px",
    },
  },
  innerWrapper: {
    width: "50%",
    [theme.breakpoints.down(767)]: {
      width: "100%",
    },
  },
  description: {
    marginTop: 0,
    opacity: "0.6",
    color: "#222",
    lineHeight: "175%",
    marginBottom: "80px",
    fontFamily: "Roboto, sans-serif",
    fontSize: "18px",
  },
}));

const TheMagic = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <OverlineText className={classes.aboutTech}>About The Tech</OverlineText>
      <div className={classes.wrapper}>
        <Typography variant="h2" className={classes.howTheMagic}>
          How The Magic
          <br />
          Happens?
        </Typography>
        <div className={classes.innerWrapper}>
          <p className={classes.description}>
            Building on advanced state-of-the-art artificial intelligence,
            <br />
            we have created an AI which uses the same mechanisms as our vision
            to understand images.
            <br />
            Together with your help, it can render your image with any artistic
            style.
          </p>
          <AnimatedButton href="/#try" label="Try it now!" id="magic-try" />
        </div>
      </div>
    </div>
  );
};

export default TheMagic;
