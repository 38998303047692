/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */
import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import SlickSlider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image/withIEPolyfill";
import clsx from "clsx";
import arrowLeft from "../images/arrow-left.svg";
import arrowRight from "../images/arrow-right.svg";

const useStyles = makeStyles(theme => ({
  container: {
    position: "absolute",
    left: "22vw",
    top: "0%",
    right: "0%",
    bottom: "135px",
    height: "auto",
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.down(767)]: {
      position: "relative",
      left: "0vw",
      bottom: "-120px",
      height: "75vw",
      marginRight: "-8vw",
    },
    [theme.breakpoints.down(479)]: {
      height: "92vw",
      marginRight: "-8vw",
    },
  },
  mask: {
    position: "relative",
    display: "block",
    overflow: "hidden",
    zIndex: "1",
    left: "0",
    right: "0",
    height: "100%",
    whiteSpace: "nowrap",
    "& div": {
      height: "100%",
    },
  },
  slider: {
    width: "100%",
    height: "100%",
  },
  slide: {
    position: "relative",
    display: "inline-block",
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.primary.main,
  },
  original: {
    position: "absolute",
    left: "0%",
    top: "0%",
    right: "0%",
    bottom: "0%",
    height: "100%",
    opacity: "0.5",
    filter: "saturate(0%)",
  },
  effectWrapper: {
    width: "0%",
    height: "100%",
    position: "absolute",
    left: "0%",
    top: "0%",
    right: "auto",
    bottom: "0%",
    overflow: "hidden",
  },
  effect: {
    position: "absolute",
    left: "0%",
    top: "0%",
    right: "auto",
    bottom: "0%",
    width: "78vw",
    height: "100%",
    animationFillMode: "forwards",
    [theme.breakpoints.down(767)]: {
      width: "92vw",
    },
  },
  unfoldedEffect: {
    animationFillMode: "forwards",
    animation: "$unfoldEffect 1s",
    animationDelay: "1.5s",
    transitionTimingFunction: "ease-in",
  },
  "@keyframes unfoldEffect": {
    "0%": {
      width: "0%",
    },
    "100%": {
      width: "100%",
    },
  },
  arrow: {
    position: "absolute",
    width: "80px",
    top: "0",
    right: "0",
    bottom: "0",
    left: "0",
    margin: "auto",
    cursor: "pointer",
    overflow: "hidden",
    color: "white",
    tapHighlightColor: "rgba(0, 0, 0, 0)",
    userSelect: "none",
  },
  arrowLeft: {
    left: "auto",
    top: "auto",
    right: "400px",
    bottom: "-86px",
    width: "auto",
    height: "auto",
    marginRight: "8vw",
    padding: "10px",
    zIndex: "3",
    [theme.breakpoints.down(991)]: {
      right: "360px",
    },
    [theme.breakpoints.down(767)]: {
      display: "none",
    },
  },
  arrowRight: {
    left: "auto",
    top: "auto",
    right: "340px",
    bottom: "-86px",
    width: "auto",
    height: "auto",
    marginRight: "8vw",
    padding: "10px",
    zIndex: "4",
    [theme.breakpoints.down(991)]: {
      right: "300px",
    },
    [theme.breakpoints.down(767)]: {
      display: "none",
    },
  },
  imgArrow: {
    cursor: "pointer",
    color: "white",
    fontSize: "40px",
    border: "0",
    maxWidth: "100%",
    verticalAlign: "middle",
    display: "inline-block",
    width: "24px",
  },
}));

const settings = {
  arrows: false,
  dots: false,
  infinite: true,
  autoplay: true,
  speed: 500,
  autoplaySpeed: 7000,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const Slider = ({ setSlide }) => {
  const classes = useStyles();
  const slider = useRef(null);

  const data = useStaticQuery(graphql`
    query {
      cornell: file(relativePath: { eq: "hero-1-original.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      cornellEffect: file(relativePath: { eq: "hero-1-effect.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      hero2: file(relativePath: { eq: "hero-2-original.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      hero2Effect: file(relativePath: { eq: "hero-2-effect.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      hero3: file(relativePath: { eq: "hero-3-original.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      hero3Effect: file(relativePath: { eq: "hero-3-effect.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const handleSlideChange = heroNumber => {
    const previousHeroNumber = heroNumber - 1 < 0 ? 2 : heroNumber - 1;

    const hero = `hero-${heroNumber}`;
    const previousHero = `hero-${previousHeroNumber}`;

    const heroes = document.getElementsByClassName(classes.effectWrapper);

    for (let i = 0; i < heroes.length; i += 1) {
      const item = heroes[i];
      if (item.id === hero) {
        item.classList.add(classes.unfoldedEffect);
      } else if (
        item.id === previousHero &&
        item.classList.contains(classes.unfoldedEffect)
      ) {
        item.classList.remove(classes.unfoldedEffect);
      }
    }
  };

  useEffect(() => {
    handleSlideChange(0);
  }, []);

  return (
    <div className={classes.container}>
      <SlickSlider
        {...settings}
        className={classes.mask}
        afterChange={handleSlideChange}
        ref={slider}
        beforeChange={(old, newIndex) => setSlide(newIndex)}
      >
        <div className={classes.slide}>
          <Img
            className={classes.original}
            objectFit="cover"
            objectPosition="50% 50%"
            fluid={data.cornell.childImageSharp.fluid}
            loading="eager"
          />
          <div className={classes.effectWrapper} id="hero-0">
            <Img
              className={classes.effect}
              objectFit="cover"
              objectPosition="50% 50%"
              fluid={data.cornellEffect.childImageSharp.fluid}
              loading="eager"
            />
          </div>
        </div>
        <div className={classes.slide}>
          <Img
            className={classes.original}
            objectFit="cover"
            objectPosition="50% 50%"
            fluid={data.hero2.childImageSharp.fluid}
            loading="eager"
          />
          <div className={classes.effectWrapper} id="hero-1">
            <Img
              className={classes.effect}
              objectFit="cover"
              objectPosition="50% 50%"
              fluid={data.hero2Effect.childImageSharp.fluid}
              loading="eager"
            />
          </div>
        </div>
        <div className={classes.slide}>
          <Img
            className={classes.original}
            objectFit="cover"
            objectPosition="50% 50%"
            fluid={data.hero3.childImageSharp.fluid}
            loading="eager"
          />
          <div className={classes.effectWrapper} id="hero-2">
            <Img
              className={classes.effect}
              objectFit="cover"
              objectPosition="50% 50%"
              fluid={data.hero3Effect.childImageSharp.fluid}
              loading="eager"
            />
          </div>
        </div>
      </SlickSlider>
      <div
        role="button"
        className={clsx(classes.arrow, classes.arrowLeft)}
        onClick={() => slider.current.slickPrev()}
      >
        <img src={arrowLeft} alt="" className={classes.imgArrow} />
      </div>
      <div
        role="button"
        className={clsx(classes.arrow, classes.arrowRight)}
        onClick={() => slider.current.slickNext()}
      >
        <img src={arrowRight} alt="" className={classes.imgArrow} />
      </div>
    </div>
  );
};

Slider.propTypes = {
  setSlide: PropTypes.func.isRequired,
};

export default Slider;
