/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus,react/jsx-one-expression-per-line,react/no-unescaped-entities */
import React from "react";
import PropTypes from "prop-types";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  checkoutSizes: {
    minHeight: 188,
    height: 188,
    overflow: "scroll",
    marginBottom: 10,
  },
  checkoutSize: {
    marginBottom: "18px",
    display: "flex",
    alignItems: "center",
  },
  checkbox: {
    marginRight: 30,
    "& svg": {
      width: 31,
      height: 31,
      [theme.breakpoints.down(479)]: {
        width: 25,
        height: 25,
      },
    },
  },
  checkboxRoot: {
    color: "#000",
    borderRadius: 0,
    padding: 4,
  },
  canvasSize: {
    display: "inline",
    fontFamily: "'Work Sans', sans-serif",
    fontSize: "22px",
    lineHeight: "22px",
    color: "#222",
    [theme.breakpoints.down(479)]: {
      fontSize: "18px",
    },
  },
  price: {
    fontFamily: "'Work Sans', sans-serif",
    fontSize: "22px",
    lineHeight: "22px",
    fontWeight: "700",
    letterSpacing: "1px",
    color: "#222",
    marginLeft: "auto",
    [theme.breakpoints.down(479)]: {
      fontSize: "18px",
    },
  },
  selectedPrice: {
    color: theme.palette.secondary.main,
  },
}));

export const Checkout = ({ canvasSizes, selected, handleChange, reverted }) => {
  const classes = useStyles();

  return (
    <div className={classes.checkoutSizes}>
      {canvasSizes.map((canvas, index) => (
        <div key={canvas.id} className={classes.checkoutSize}>
          <Checkbox
            color="secondary"
            size="medium"
            checked={index === selected}
            className={classes.checkbox}
            onChange={handleChange}
            value={index}
            disableRipple
            classes={{ root: classes.checkboxRoot }}
          />
          <div className={classes.canvasSize}>
            {reverted
              ? `${canvas.canvasWidth}'' x ${canvas.canvasHeight}''`
              : `${canvas.canvasHeight}'' x ${canvas.canvasWidth}''`}
          </div>
          <div
            className={clsx(classes.price, {
              [classes.selectedPrice]: index === selected,
            })}
          >
            ${canvas.price}
          </div>
        </div>
      ))}
    </div>
  );
};

Checkout.propTypes = {
  canvasSizes: PropTypes.array,
  handleChange: PropTypes.func.isRequired,
  selected: PropTypes.number,
  reverted: PropTypes.bool,
};
