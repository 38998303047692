import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import ButtonBase from "@material-ui/core/ButtonBase";
import { connect } from "react-redux";
import ReactImg from "react-image";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as Data from "../models/data";
import StyleUpload from "./style-upload";
import americanGirl from "../images/styles/american_girl.jpg";
import starryNight from "../images/styles/starry_night.jpg";
import greatWave from "../images/styles/great_wave.jpg";
import picasso from "../images/styles/picasso.jpg";
import scream from "../images/styles/scream.jpg";
import seatedNude from "../images/styles/seated_nude.jpg";
import wheatfieldCrows from "../images/styles/wheatfield_crows.jpg";
import mosaic from "../images/styles/mosaic.jpg";
import { logStyleSelected } from "./eventLogger";

const {
  StylizeMessage: { Style },
} = require("../api/style-transfer_grpc_web_pb");

const useStyles = makeStyles(theme => ({
  artStyles: {
    height: 480,
    display: "block",
    overflow: "scroll",
    padding: "5px",
    border: "1px solid rgba(34, 34, 34, 0.2)",
    backgroundColor: "#fff",
    boxSizing: "border-box",
    [theme.breakpoints.down(767)]: {
      height: "285px",
      minHeight: 151,
    },
    [theme.breakpoints.down(479)]: {
      "-webkit-overflow-scrolling": "touch",
      width: "142vw",
      overflowX: "hidden",
      marginRight: "8vw",
      marginLeft: "8vw",
      height: "100%",
    },
    [theme.breakpoints.down(380)]: {
      width: "150vw",
    },
  },
  imageWrapper: {
    width: "100%",
    height: "100%",
    position: "initial !important",
    objectFit: "cover",
    objectPosition: "50% 50%",
  },
  image: {
    "-webkit-transform": "translateZ(0)",
    boxSizing: "border-box",
    width: "calc(33% - 10px)",
    height: 100,
    float: "left",
    margin: 5,
    [theme.breakpoints.down(991)]: {
      width: "calc(50% - 10px)",
    },
    [theme.breakpoints.down(767)]: {
      width: "25vw",
      height: "25vw",
    },
    [theme.breakpoints.down(479)]: {
      width: "32vw",
      height: "32vw",
    },
  },
  selected: {
    border: `6px solid ${theme.palette.secondary.main}`,
  },
  uploadedStyle: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    objectPosition: "50% 50%",
  },
  loader: {
    color: "#222",
  },
}));

const ArtStyles = ({ setArtStyle, artStyle, uploadedStyle }) => {
  const classes = useStyles();

  const images = [
    [americanGirl, Style.AMERICAN_GIRL],
    [starryNight, Style.STARRY_NIGHT],
    [greatWave, Style.GREAT_WAVE],
    [picasso, Style.PICASSO],
    [scream, Style.SCREAM],
    [seatedNude, Style.SEATED_NUDE],
    [wheatfieldCrows, Style.WHEATFIELD_CROWS],
    [mosaic, Style.MOSAIC],
  ];

  const handleClick = imageKey => () => {
    setArtStyle(imageKey);
    logStyleSelected(imageKey);
  };

  return (
    <div className={classes.artStyles}>
      {uploadedStyle ? (
        <ButtonBase className={clsx(classes.image, classes.selected)} disabled>
          <ReactImg
            src={uploadedStyle.url}
            alt="Uploaded style"
            className={classes.uploadedStyle}
            loader={<CircularProgress className={classes.loader} />}
          />
        </ButtonBase>
      ) : (
        <StyleUpload />
      )}
      {images.map(imageAndKey => (
        <ButtonBase
          key={imageAndKey[1]}
          className={clsx(classes.image, {
            [classes.selected]: imageAndKey[1] === artStyle,
          })}
          disableRipple
          onClick={handleClick(imageAndKey[1])}
        >
          <img className={classes.imageWrapper} src={imageAndKey[0]} alt="" />
        </ButtonBase>
      ))}
    </div>
  );
};

ArtStyles.propTypes = {
  setArtStyle: PropTypes.func.isRequired,
  artStyle: PropTypes.number.isRequired,
  uploadedStyle: PropTypes.object,
};

const mapStateToProps = state => ({
  artStyle: Data.getArtStyle(state),
  uploadedStyle: Data.getUploadedStyle(state),
});

const mapDispatchToProps = dispatch => ({
  setArtStyle: Data.setArtStyle(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ArtStyles);
