import uploadcare from "uploadcare-widget";
import PropTypes from "prop-types";
import { connect, useStore } from "react-redux";
import clsx from "clsx";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import ButtonBase from "@material-ui/core/ButtonBase";
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import * as Data from "../models/data";
import { extractImageInfo } from "../utils";
import config from "../../config";
import * as eventLogger from "./eventLogger";

const useStyles = makeStyles(theme => ({
  imageWrapper: {
    width: "100%",
    height: "100%",
  },
  image: {
    "-webkit-transform": "translateZ(0)",
    boxSizing: "border-box",
    width: "calc(33% - 10px)",
    height: 100,
    float: "left",
    margin: 5,
    [theme.breakpoints.down(991)]: {
      width: "calc(50% - 10px)",
    },
    [theme.breakpoints.down(767)]: {
      width: "25vw",
      height: "25vw",
    },
    [theme.breakpoints.down(479)]: {
      width: "32vw",
      height: "32vw",
    },
  },
  uploadYourOwn: {
    cursor: "pointer",
    flexDirection: "column",
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      "& > span": {
        lineHeight: 1.5,
      },
      padding: 0,
    },
  },
  uploadText: {
    marginTop: "10px",
    opacity: 0.5,
    color: "#fff",
    fontSize: "12px",
    lineHeight: "200%",
    fontFamily: "Roboto",
  },
  uploadIcon: {
    width: "20%",
    marginTop: "20px",
  },
  selected: {
    border: `6px solid ${theme.palette.secondary.main}`,
  },
  loader: {
    color: "#fff",
  },
}));

const StyleUpload = ({ setUploadedStyle, setError }) => {
  const classes = useStyles();
  const store = useStore();
  const [loadingStyle, setLoadingStyle] = useState(false);

  const handleOwnStyleClick = () => {
    eventLogger.logCustomStyleClick();

    const uploadedImage = Data.getUploadedImage(store.getState());

    if (
      uploadedImage &&
      uploadedImage.originalWidth * uploadedImage.originalHeight < 750 * 750
    ) {
      setError(
        "Uploaded image is too small to use custom style. Image must be larger than 750x750 pixels for custom styles."
      );
      eventLogger.logCustomStyleTooSmall(uploadedImage);
      return;
    }

    const dialog = uploadcare.openDialog(null, null, {
      publicKey: config.uploadCarePublicKey,
      imagesOnly: true,
      tabs: "instagram file url gdrive",
      previewStep: false,
    });

    dialog.done(instance => {
      setLoadingStyle(true);
      instance.promise().done(info => {
        const newImage = extractImageInfo(info);

        if (newImage.originalWidth * newImage.originalHeight < 562500) {
          setError(
            "Image is too small. Image must be larger than 750x750 pixels."
          );
          eventLogger.logCustomStyleTooSmall(newImage);
        } else {
          setUploadedStyle(newImage);
        }

        setLoadingStyle(false);
      });
    });
  };

  return (
    <>
      <ButtonBase
        onClick={handleOwnStyleClick}
        className={clsx(classes.image, classes.uploadYourOwn)}
        disableRipple
        disabled={loadingStyle}
      >
        {loadingStyle ? (
          <CircularProgress className={classes.loader} />
        ) : (
          <>
            <img
              src="https://assets.website-files.com/5e2846866ffaa8c8083d6bc8/5e2c36e9ba7fffb5ba99a4a6_upload-icon-2.svg"
              className={classes.uploadIcon}
              alt="plus icon"
            />
            <Typography className={classes.uploadText}>Upload style</Typography>
          </>
        )}
      </ButtonBase>
    </>
  );
};

StyleUpload.propTypes = {
  setUploadedStyle: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  setUploadedStyle: Data.setUploadedStyle(dispatch),
  setError: Data.setError(dispatch),
});

export default connect(null, mapDispatchToProps)(StyleUpload);
