import React from "react";
import { makeStyles } from "@material-ui/core";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image/withIEPolyfill";
import Typography from "@material-ui/core/Typography";
import OverlineText from "./typography/overline";
import AnimatedButton from "./animated-button";

const useStyles = makeStyles(theme => ({
  container: {
    position: "relative",
    padding: "80px 8vw",
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.down(991)]: {
      marginTop: "80px",
      paddingTop: "0px",
    },
  },
  wrapper: {
    width: "50%",
    paddingRight: "120px",
    [theme.breakpoints.down(991)]: {
      width: "100%",
    },
    [theme.breakpoints.down(479)]: {
      paddingRight: 0,
    },
  },
  wall: {
    position: "absolute",
    left: "auto",
    top: "0%",
    right: "0%",
    bottom: "-80px",
    zIndex: "9",
    width: "50%",
    [theme.breakpoints.down(991)]: {
      position: "relative",
      left: "-8vw",
      top: "-80px",
      width: "92vw",
      height: "50vw",
    },
    [theme.breakpoints.down(479)]: {
      height: "100vw",
    },
  },
  wallImg: {
    width: "100%",
    height: "100%",
  },
  likeTheMagic: {
    display: "block",
    color: "#fff",
    marginBottom: "30px",
  },
  onYourWall: {
    marginTop: "0px",
    fontFamily: "'Work Sans', sans-serif",
    fontSize: "48px",
    lineHeight: "125%",
    fontWeight: "600",
    color: "#fff",
    marginBottom: "60px",
    [theme.breakpoints.down(991)]: {
      fontSize: "36px",
    },
  },
  description: {
    marginBottom: "120px",
    color: "#fff",
    opacity: 0.6,
    lineHeight: "175%",
    marginTop: 0,
    fontFamily: "Roboto, sans-serif",
    fontSize: "18px",
  },
  button: {
    border: "1px solid #fff",
    backgroundColor: "transparent",
    padding: "15px 32px 17px",
  },
}));

const OnYourWall = () => {
  const classes = useStyles();

  const data = useStaticQuery(graphql`
    query {
      wall: file(relativePath: { eq: "hero-1-effect.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <div className={classes.wall}>
          <Img
            className={classes.wallImg}
            objectFit="cover"
            objectPosition="50% 50%"
            fluid={data.wall.childImageSharp.fluid}
          />
        </div>
        <Typography variant="h2" className={classes.onYourWall}>
          On Your Wall
        </Typography>
        <p className={classes.description}>
          We have created an AI which uses the same mechanisms as our vision to
          understand images. Together with your input, it can render your image
          with any artistic style.
        </p>
        <AnimatedButton
          label="See Physical Prints"
          id="see-prints"
          className={classes.button}
          href="/physical-prints"
        />
      </div>
    </div>
  );
};

export default OnYourWall;
